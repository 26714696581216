import {
  BooleanParam,
  DateTimeParam,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';

import { RiskHistoryOrderBy } from '@/globalTypes';
import { EventTypeParam, RiskHistoryOrderByParam } from '@/utils/query/params';

export const queryParams = {
  after: StringParam,
  before: StringParam,
  desc: withDefault(BooleanParam, true),
  eventType: EventTypeParam,
  first: withDefault(NumberParam, 20),
  from: DateTimeParam,
  last: NumberParam,
  orderBy: withDefault(RiskHistoryOrderByParam, RiskHistoryOrderBy.processedAt),
  to: DateTimeParam,
};
