import { formatISO } from 'date-fns';
import gql from 'graphql-tag';
import { useMemo } from 'react';
import { useQuery } from 'urql';
import { useQueryParams } from 'use-query-params';

import { RangeDate } from '@/globalTypes';
import { mapVariables } from '@/utils';
import { makeOrderBy, makePagination } from '@/utils/pagination';
import {
  PlayerRiskAssessmentHistory,
  PlayerRiskAssessmentHistoryVariables,
} from './__generated__/PlayerRiskAssessmentHistory';
import { queryParams } from './queryParams';

const QUERY = gql`
  query PlayerRiskAssessmentHistory(
    $after: String
    $before: String
    $desc: Boolean
    $first: Int
    $last: Int
    $playerGlobalId: String
    $eventType: EventType
    $orderBy: RiskHistoryOrderBy
    $processedAt: RangeDate
  ) {
    viewer {
      id
      riskAssessmentHistory(
        playerGlobalId: $playerGlobalId
        after: $after
        before: $before
        desc: $desc
        first: $first
        last: $last
        eventType: $eventType
        orderBy: $orderBy
        processedAt: $processedAt
      ) {
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
        edges {
          node {
            eventId
            playerGlobalId
            playerId
            eventType
            comment
            outcomes {
              name
              ... on FinalRiskLevelUpdated {
                riskLevelBefore
                riskLevel
              }
              ... on RiskLevelUpdated {
                regionId
                riskId
                riskLevelBefore
                riskLevel
              }
              ... on RiskOverrideAdded {
                riskLevel
              }
              ... on RiskOverrideRemoved {
                riskLevel
              }
              ... on ReviewApproved {
                reviewTrigger {
                  byRisks {
                    riskId
                  }
                }
                name
              }
              ... on ReviewNeeded {
                reviewTrigger {
                  byRisks {
                    riskId
                  }
                }
                name
              }
              ... on ReviewPending {
                name
              }
              ... on ReviewRejected {
                reviewTrigger {
                  byRisks {
                    riskId
                  }
                }
                name
              }
            }
            initiator {
              ... on PlayerInitiator {
                player {
                  firstName
                }
              }
              ... on AgentInitiator {
                agent {
                  agentId
                  email
                  firstName
                  lastName
                }
              }
            }
            processedAt
          }
        }
      }
    }
  }
`;

export default function usePlayerRiskAssessment(playerGlobalId: string) {
  const [query, setQuery] = useQueryParams(queryParams);

  const processedAt = useMemo((): RangeDate | undefined => {
    if (query.from && query.to) {
      return {
        from: formatISO(query.from),
        to: formatISO(query.to),
      };
    }

    return undefined;
  }, [query.from, query.to]);

  const [{ data, fetching }, refresh] = useQuery<
    PlayerRiskAssessmentHistory,
    PlayerRiskAssessmentHistoryVariables
  >({
    query: QUERY,
    variables: {
      playerGlobalId,
      ...mapVariables(query),
      processedAt,
    },
  });

  return {
    refresh,
    fetching,
    defaultFilter: query,
    updateFilter: (values: {}) => setQuery(values, 'replaceIn'),
    ...makeOrderBy(query, setQuery),
    ...makePagination(
      query,
      setQuery,
      data?.viewer.riskAssessmentHistory.pageInfo,
    ),
    history: data?.viewer.riskAssessmentHistory?.edges?.map(
      (edge) => edge?.node,
    ),
  };
}
